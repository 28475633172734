export const CAMPAIGN_TYPES = {
  ONGOING: 'ongoing',
  ONE_OFF: 'one_off',
};

export const ONE_OFF_STEPS = {
  INITIAL_SETUP: 0,
  TEMPLATE: 1,
  AUDIENCE: 2,
};

export const STEPS = [
  { key: 'CAMPAIGN.ADD.STEPS.INITIAL_SETUP.STEP_TITLE', label: 'initialSetup' },
  { key: 'CAMPAIGN.ADD.STEPS.TEMPLATE.STEP_TITLE', label: 'template' },
  { key: 'CAMPAIGN.ADD.STEPS.AUDIENCE.STEP_TITLE', label: 'audience' },
];

export const VORDESK_VARIABLES = [
  {
    key: 'name',
    i18n_title_key: 'CAMPAIGN.ADD.TEMPLATE_VARIABLES.NAME.TITLE',
    i18n_desc_key: 'CAMPAIGN.ADD.TEMPLATE_VARIABLES.NAME.DESC',
  },
  {
    key: 'phone_number',
    i18n_title_key: 'CAMPAIGN.ADD.TEMPLATE_VARIABLES.PHONE_NUMBER.TITLE',
    i18n_desc_key: 'CAMPAIGN.ADD.TEMPLATE_VARIABLES.PHONE_NUMBER.DESC',
  },
  {
    key: 'email',
    i18n_title_key: 'CAMPAIGN.ADD.TEMPLATE_VARIABLES.EMAIL.TITLE',
    i18n_desc_key: 'CAMPAIGN.ADD.TEMPLATE_VARIABLES.EMAIL.DESC',
  },
];

export const CAMPAIGN_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PROCESSING: 'processing',
  IN_PROGRESS: 'in_progress',
  ERROR: 'error',
};

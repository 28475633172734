import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import CampaignsAPI from '../../api/campaigns';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { CAMPAIGNS_EVENTS } from '../../helper/AnalyticsHelper/events';
import { CAMPAIGN_TYPES } from '../../../shared/constants/campaign';

export const state = {
  records: [],
  currentStep: 0,
  meta: {
    count: 0,
    currentPage: 1,
  },
  uiFlags: {
    isFetching: false,
    isCreating: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getCampaigns: _state => campaignType => {
    return _state.records.filter(
      record => record.campaign_type === campaignType
    );
  },
  getAllCampaigns: _state => {
    return _state.records;
  },
  getCurrentStep: _state => {
    return _state.currentStep;
  },
  getSortedCampaigns(_state) {
    return (sortOrder, property) => {
      const records = _state.records.filter(
        record => record.campaign_type === CAMPAIGN_TYPES.ONE_OFF
      );

      const compare = (a, b) => {
        if (
          records.every(record => typeof record.metrics[property] === 'number')
        ) {
          return sortOrder === 'asc'
            ? a.metrics[property] - b.metrics[property]
            : b.metrics[property] - a.metrics[property];
        }

        if (property === 'created_at' || property === 'scheduled_at') {
          const dateA = new Date(a[property]);
          const dateB = new Date(b[property]);
          return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        }

        if (property === 'sender') {
          return sortOrder === 'asc'
            ? a[property].name.localeCompare(b[property])
            : b[property].name.localeCompare(a[property]);
        }

        return sortOrder === 'asc'
          ? a[property].localeCompare(b[property])
          : b[property].localeCompare(a[property]);
      };

      return [...records].sort(compare);
    };
  },
  getMeta: $state => {
    return $state.meta;
  },
};

export const actions = {
  get: async function getCampaigns(
    { commit },
    {
      status = '',
      since = '',
      until = '',
      page = 1,
      campaignType = CAMPAIGN_TYPES.ONE_OFF,
    } = {}
  ) {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: true });
    try {
      const response = await CampaignsAPI.get(
        since,
        until,
        status,
        page,
        campaignType
      );
      commit(types.SET_CAMPAIGNS, response.data.payload);
      commit(types.SET_CAMPAIGN_META, response.data.meta);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createCampaign({ commit }, campaignObj) {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isCreating: true });
    try {
      const response = await CampaignsAPI.create(campaignObj);
      commit(types.ADD_CAMPAIGN, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isUpdating: true });
    try {
      const response = await CampaignsAPI.update(id, updateObj);
      AnalyticsHelper.track(CAMPAIGNS_EVENTS.UPDATE_CAMPAIGN);
      commit(types.EDIT_CAMPAIGN, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isDeleting: true });
    try {
      await CampaignsAPI.delete(id);
      AnalyticsHelper.track(CAMPAIGNS_EVENTS.DELETE_CAMPAIGN);
      commit(types.DELETE_CAMPAIGN, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isDeleting: false });
    }
  },
  getById: async function getCampaignById({ commit }, id) {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: true });
    try {
      const response = await CampaignsAPI.show(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: false });
    }
  },
  nextStep: ({ commit }, value) => {
    commit(types.SET_CURRENT_STEP, value);
  },
};

export const mutations = {
  [types.SET_CAMPAIGN_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_CURRENT_STEP](_state, step) {
    _state.currentStep = step;
  },
  [types.SET_CAMPAIGN_META]: ($state, data) => {
    const { count, current_page: currentPage } = data;
    $state.meta.count = count;
    $state.meta.currentPage = currentPage;
  },

  [types.ADD_CAMPAIGN]: MutationHelpers.create,
  [types.SET_CAMPAIGNS]: MutationHelpers.set,
  [types.EDIT_CAMPAIGN]: MutationHelpers.update,
  [types.DELETE_CAMPAIGN]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
